<template>
  <!-- tabvertical이 있어야 탭에 테두리가 생김 -->
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 tabvertical">
    <c-tab
      type="vertical"
      :tabItems="tabItems"
      :height.sync="tabHeight"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :popupParam.sync="popupParam"
          :nearAccident.sync="nearAccident"
          :attachInfo1="attachInfo1"
          :attachInfo2="attachInfo2"
          :isApprDisabled="isApprDisabled"
          :height="height"
          :isOld.sync="isOld"
          :isVendor.sync="isVendor"
          :isUser.sync="isUser"
          :disabled.sync="disabled"
          @getDetail="getDetail"
          @closePopup="closePopup"
        />
      </template>
    </c-tab>
  </div>
</template>
<script>
export default {
  name: 'nearRegisterTab',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        iimNearAccidentId: '',
        iimStatusNearCd: '',
      }),
    },
    nearAccident: {
      type: Object,
      default: () => ({
        iimNearAccidentId: '',
        plantCd: '',
        iimNearAccidentNo: '',
        iimNearAccidentName: '',
        iimStatusNearCd: '',
        reportDeptCd: '',
        reportUserId: '',
        reportDt: '',
        iimNearKindCds: '',
        iimGradeCd: null,
        discovererId: '',
        occurrenceDt: '',
        occurrenceLocation: '',
        reviewDeptCd: '',
        weatherCd: 'WC000001',
        emergencyMeasures: '',
        overviewAccidentSituation: '',
        occurrenceModeLargeCd: null,
        occurrenceModeSmallCd: null,
        directCause: '',
        indirectCause: '',
        openning: '',
        injurious: '',
        iimNearDiscernCds: null,
        iimNearFunctionCds: null,
        iimNearFeelCds: null,
        iimNearEquipCds: null,
        iimNearManageCds: null,
        processCd: '',
        frequencyRisk: '',
        strengthRisk: '',
        totalRisk: '',
        ramRiskAssessmentPlanId: '',
        auditScore: null,
        auditOpinion: '',
        bounty: null,
        requestManagerId: '',
        requestFlag: 'N',
        vendorFlag: '',
        approvalRequestStatusCd: '', // 결재관련 결재상태 요청승인
        sysApprovalRequestRequestId: '', // 결재관련 결재요청코드 요청승인
        approvalReviewStatusCd: '', // 결재관련 결재상태 검토승인
        sysApprovalReviewRequestId: '', // 결재관련 결재요청코드 검토승인
        approvalAuditStatusCd: '', // 결재관련 결재상태 심사승인
        sysApprovalAuditRequestId: '', // 결재관련 결재요청코드 심사승인
        preventions: [],
      }),
    },
    attachInfo1: {
      type: Object,
      default: function() {
        return {
          isSubmit: '',
          taskClassCd: 'NEAR_FIRST_PICTURE',
          taskKey: '',
        }
      },  
    },
    attachInfo2: {
      type: Object,
      default: function() {
        return {
          isSubmit: '',
          taskClassCd: 'NEAR_SECOND_PICTURE',
          taskKey: '',
        }
      },  
    },
    isApprDisabled: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
    isOld: {
      type: Boolean,
      default: false
    },
    isUser: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      tab: 'nearRegisterInfo',
      tabItems: [
        { name: 'nearRegisterInfo', icon: 'edit', label: '기본정보', component: () => import(`${'./nearRegisterInfo.vue'}`) },
        { name: 'nearRelationPicture', icon: 'camera_enhance', label: '관련 사진', component: () => import(`${'./nearRelationPicture.vue'}`) }
      ],
      editable: false,
    };
  },
  computed: {
    tabHeight() {
      let numHeight = this.height ? Number(this.$_.replace(this.height, 'px', '')) : 850;
      numHeight = numHeight - 30
      return numHeight + 'px';
    },
    isVendor() {
      return this.isOld ? this.nearAccident.vendorFlag === '1' : !this.isUser
    },
    vendorDisabled() {
      return this.isVendor ? 
        !this.isUser ? 
          this.nearAccident.requestFlag === 'Y' : 
          this.nearAccident.requestManagerId !== this.$store.getters.user.userId 
        : false;
    },
    disabled() {
      return (this.isOld 
          && (this.nearAccident.iimStatusNearCd !== 'ISNC000001'
            || this.nearAccident.approvalRequestStatusCd === 'ASC0000001'
            || (this.isUser ? 
              this.nearAccident.vendorFlag === '1' ? 
                this.nearAccident.requestManagerId !== this.$store.getters.user.userId :
                this.nearAccident.reportDeptCd !== this.$store.getters.user.deptCd 
                :
              this.nearAccident.reportDeptCd !== this.$store.getters.user.deptCd
            )
          )
        )
        || this.vendorDisabled
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      // list setting 
    },
    getDetail(data) {
      this.$emit('getDetail', data);  
    },
    closePopup(data) {
      this.$emit('closePopup', data);  
    },
  }
};
</script>