var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 tabvertical",
    },
    [
      _c("c-tab", {
        attrs: {
          type: "vertical",
          tabItems: _vm.tabItems,
          height: _vm.tabHeight,
        },
        on: {
          "update:height": function ($event) {
            _vm.tabHeight = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (tab) {
              return [
                _c(tab.component, {
                  tag: "component",
                  attrs: {
                    popupParam: _vm.popupParam,
                    nearAccident: _vm.nearAccident,
                    attachInfo1: _vm.attachInfo1,
                    attachInfo2: _vm.attachInfo2,
                    isApprDisabled: _vm.isApprDisabled,
                    height: _vm.height,
                    isOld: _vm.isOld,
                    isVendor: _vm.isVendor,
                    isUser: _vm.isUser,
                    disabled: _vm.disabled,
                  },
                  on: {
                    "update:popupParam": function ($event) {
                      _vm.popupParam = $event
                    },
                    "update:popup-param": function ($event) {
                      _vm.popupParam = $event
                    },
                    "update:nearAccident": function ($event) {
                      _vm.nearAccident = $event
                    },
                    "update:near-accident": function ($event) {
                      _vm.nearAccident = $event
                    },
                    "update:isOld": function ($event) {
                      _vm.isOld = $event
                    },
                    "update:is-old": function ($event) {
                      _vm.isOld = $event
                    },
                    "update:isVendor": function ($event) {
                      _vm.isVendor = $event
                    },
                    "update:is-vendor": function ($event) {
                      _vm.isVendor = $event
                    },
                    "update:isUser": function ($event) {
                      _vm.isUser = $event
                    },
                    "update:is-user": function ($event) {
                      _vm.isUser = $event
                    },
                    "update:disabled": function ($event) {
                      _vm.disabled = $event
                    },
                    getDetail: _vm.getDetail,
                    closePopup: _vm.closePopup,
                  },
                }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.tab,
          callback: function ($$v) {
            _vm.tab = $$v
          },
          expression: "tab",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }